import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, css, respondFrom } from '@styles';
import VideoPlayer from '@components/common/VideoPlayer';
import { ImageSharp } from '@content/types/general';

interface HeroVideoPlayerProps {
  videoUrl: string;
  videoThumbnail?: ImageSharp;
  videoThumbnailAlt?: string;
}

const Wrapper = styled.div`
  padding: 10px 10px;
  z-index: 11;

  ${respondFrom(
    breakpoints.lg,
    css`
      position: absolute;
      bottom: -198px;
      left: 50%;
      right: clamp(20px, 5%, 10%);
    `
  )}

  ${respondFrom(
    breakpoints.xl,
    css`
      bottom: -228px !important;
      right: clamp(20px, 10%, 40%);
    `
  )}
`;

const MaxWidthContainer = styled.div`
  border-radius: 20px;
  border: 1px solid #009944;
  padding: 20px;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-left: auto;
      position: relative;
      max-width: 540px;
    `
  )}
`;

const PlayerContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
`;

const ArrowShaft = styled.div`
  display: none;

  ${respondFrom(
    breakpoints.lg,
    css`
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      right: 550px;
      bottom: 55px;
      border-top: 1px solid #009944;
      border-left: 1px solid #009944;
      min-width: 10px;
    `
  )}

  ${respondFrom(
    breakpoints.xl,
    css`
      bottom: 75px;
    `
  )}

`;

const HeroVideoPlayer: React.FC<HeroVideoPlayerProps> = props => {
  return (
    <Wrapper className={'wrapper'}>
      <ArrowShaft />
      <MaxWidthContainer>
        <PlayerContainer>
          <VideoPlayer
            image={props.videoThumbnail ? props.videoThumbnail.childImageSharp : null}
            imageAlt={props.videoThumbnailAlt}
            videoUrl={props.videoUrl}
          />
        </PlayerContainer>
      </MaxWidthContainer>
    </Wrapper>
  );
};

export default HeroVideoPlayer;
