import React from 'react';
import { css } from '@emotion/core';
import Image, { GatsbyImageProps } from 'gatsby-image';
import styled from '@emotion/styled';

import Container from '@components/layout/Container';
import { colors, respondTo, respondFrom, breakpoints } from '@styles';

const HeroBgContainer = styled.section<HeroBgContainerProps>`
  color: ${colors.white};
  position: relative;
  min-height: ${props => (props.noMinHeight ? '0 !important' : false)};

  ${respondFrom(
    breakpoints.mobileMenuThreshold,
    css`
      display: flex;
      max-height: 69vh; /* make content below always visible */
      min-height: 570px;
      flex-direction: column;
    `
  )};
`;

const Overlay = styled.div`
  text-align: left;
  margin: 0px auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  text-align: left;
  padding: 40px 0;

  ${respondFrom(
    breakpoints.md,
    css`
      justify-content: center;
    `
  )}
  ${respondFrom(
    breakpoints.lg,
    css`
      top: 0;
      bottom: 0;
    `
  )}
`;

const BgImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  z-index: -1;
  height: auto;

  /* @media (min-height: 830px) {
    max-height: 66vh;
  } */

  & img {
    object-fit: cover !important;
    object-position: center !important;
  }

  ${respondTo(
    breakpoints.md,
    css`
      min-height: 550px !important;
      max-height: 70vh;
    `
  )}
`;

interface HeroBgProps extends GatsbyImageProps {
  additionalContent?: React.ReactElement | null;
  className?: string;
  noMinHeight?: boolean;
}

interface HeroBgContainerProps {
  noMinHeight?: boolean;
}

export default class HeroBg extends React.Component<HeroBgProps> {
  render() {
    const { children, className, noMinHeight } = this.props;
    return (
      <HeroBgContainer className={className || ''} noMinHeight={noMinHeight}>
        {this.props.fixed && (
          <BgImage fixed={this.props.fixed} imgStyle={{ objectPosition: 'top center' }} loading={this.props.loading ? this.props.loading : 'lazy'}/>
        )}
        <Overlay>
          <Container>{children}</Container>
        </Overlay>

        {this.props.additionalContent}
      </HeroBgContainer>
    );
  }
}
