import * as React from 'react';
import styled from '@emotion/styled';

import {
  dimensions,
  colors,
  animation,
  mixins,
  respondFrom,
  respondTo,
  breakpoints,
  css,
} from '@styles';
import { Link } from '@utils';
import Icon from './Icon';
import { GatsbyLinkProps } from 'gatsby';

const StyledButtonLink = styled.div`
  a {
    position: relative;
    display: inline-block;
    text-align: center;
    padding: 1em 1.4em 0.9em;
    text-transform: uppercase;
    text-decoration: none !important;
    font-weight: 500;
    letter-spacing: -0.025em; /* 0.4px / 16px */
    color: ${colors.white};
    position: relative;
    white-space: nowrap;
    z-index: 10;

    transition: ${animation.fast}ms ease-out;
    transition-property: background-position, transform;

    ${respondFrom(
      breakpoints.md,
      css`
        padding: 1em 2.6em 0.9em;
      `
    )}

    // color versions
    &.color-primary {
      ${mixins.gradientGreen}
    }

    &.color-text {
      background-image: none;
      background: transparent;
      padding-left: 0;
      padding-right: 0;
      color: ${colors.green.default};
      letter-spacing: 0;

      p > & {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &.color-text-light {
      background-image: none;
      padding-left: 0;
      padding-right: 0;
      color: ${colors.text.article};
      letter-spacing: 0;
    }

    &.disabled {
      background-image: none;
      background-color: ${colors.text.light};
      pointer-events: none;
    }

    // size versions
    &.size-small {
      font-size: ${dimensions.fontSize.small}px;
    }

    &.size-medium {
      font-size: ${dimensions.fontSize.small}px;

      ${respondFrom(
        breakpoints.md,
        css`
          font-size: ${dimensions.fontSize.regular}px;
        `
      )}
    }

    &.size-basket {
      padding: 1.25em 0.8em 1.15em;

      .button-icon {
        margin-left: 10px;
      }
    }

    &.width-full {
      display: block;
    }

    /* &.no-click {
    pointer-events: none;
  } */

    .button-text {
      position: relative;
      display: inline-block;

      transition: ${animation.fast}ms ease-out;
      transition-property: transform;
    }

    .button-icon {
      margin-left: 20px;

      transition: ${animation.fast}ms ease-out;
      transition-property: transform;

      ${respondTo(
        breakpoints.md,
        css`
          display: none;
        `
      )}
    }

    &:not(.disabled) {
      &:hover,
      &:active,
      &:focus,
      .hoverable:hover &:not(.no-parent-hovering) {
        background-position: -20% center;

        .button-text {
          transform: translateX(1px);
        }
        .button-icon {
          transform: translateX(4px);
        }
      }
      &:active {
        transform: translateX(1px);
      }
    }

    &.to-left {
      .button-icon {
        margin-left: 0;
        margin-right: 20px;
      }

      &:not(.disabled) {
        &:hover,
        &:active,
        &:focus {
          background-position: 20% center;

          .button-text {
            transform: translateX(-1px);
          }
          .button-icon {
            transform: translateX(-4px);
          }
        }
        &:active {
          transform: translateX(-1px);
        }
      }
    }

    &.long-text {
      padding: 1em 10px 0.9em;

      ${respondFrom(
        breakpoints.md,
        css`
          padding: 1em 2.6em 0.9em;
        `
      )}

      .hide-on-mobile {
        display: none;

        ${respondFrom(
          breakpoints.md,
          css`
            display: inline;
          `
        )}
      }
    }

    &.small-on-mobile {
      ${respondTo(
        breakpoints.lg,
        css`
          font-size: ${dimensions.fontSize.small}px;
        `
      )}
    }
  }
`;

interface ButtonLinkProps extends GatsbyLinkProps<{}> {
  color?: 'primary' | 'secondary' | 'text' | 'text-light' | 'inactive';
  size?: 'small' | 'medium' | 'basket';
  icon?: 'arrow-right' | 'arrow-left';
  className?: string;
  disabled?: boolean;
  width?: 'full';
  propagateClickEvent?: boolean;
  longText?: boolean;
  extend?: boolean;
  target?: string;
  smallOnMobile?: boolean;
}

interface ButtonContentProps {
  icon?: 'arrow-right' | 'arrow-left';
}

const ButtonContent: React.FC<ButtonContentProps> = ({ icon, children }) => (
  <>
    {icon && icon === 'arrow-left' ? (
      <Icon className="button-icon" type="arrow-left" inheritColor={true}></Icon>
    ) : (
      ''
    )}

    <span className="button-text">{children}</span>

    {icon && icon === 'arrow-right' ? (
      <Icon className="button-icon" type="arrow-right" inheritColor={true}></Icon>
    ) : (
      ''
    )}
  </>
);

const ButtonLink: React.FC<ButtonLinkProps> = ({
  children,
  className,
  to,
  color,
  size,
  icon,
  disabled,
  width,
  propagateClickEvent,
  longText,
  extend,
  target,
  smallOnMobile,
}) => (
  <StyledButtonLink>
    {extend ? (
      <a
        href={to}
        target={target && target !== '' ? target : '_blank'}
        rel="noopener noreferrer"
        className={`
          ${className || ''}
          ${'color-' + (color || 'primary')}
          ${'size-' + (size || 'medium')}
          ${disabled ? 'disabled' : ''}
          ${width === 'full' ? 'width-full' : ''}
          ${longText ? 'long-text' : ''}
          ${smallOnMobile ? 'small-on-mobile' : ''}
        `}
        onClick={e => {
          propagateClickEvent ? null : e.stopPropagation();
          //   to.charAt(0) === '#' ? null : null;
        }}
      >
        <ButtonContent icon={icon}>{children}</ButtonContent>
      </a>
    ) : (
      <Link
        to={to}
        className={`
          ${className || ''}
          ${'color-' + (color || 'primary')}
          ${'size-' + (size || 'medium')}
          ${disabled ? 'disabled' : ''}
          ${width === 'full' ? 'width-full' : ''}
          ${longText ? 'long-text' : ''}
          ${smallOnMobile ? 'small-on-mobile' : ''}
        `}
        onClick={e => {
          propagateClickEvent ? null : e.stopPropagation();
          //   to.charAt(0) === '#' ? null : null;
        }}
      >
        <ButtonContent icon={icon}>{children}</ButtonContent>
      </Link>
    )}
  </StyledButtonLink>
);

export default ButtonLink;
