import React from 'react';
import styled from '@emotion/styled';
import Image from 'gatsby-image';

import { ImageSharp } from '@content/types/general';
import { respondTo, breakpoints, css } from '@styles';

const StyledOverlayImageContainer = styled.div`
  z-index: 2;

  ${respondTo(
    breakpoints.lg,
    css`
      /* TODO */
      display: none;
    `
  )}

  > .gatsby-image-wrapper {
    /* expecting gatsby image */
    position: absolute !important;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
`;

interface Props {
  image: ImageSharp;
  alt?: string;

  className?: string;
}

const OverlaySectionImage: React.FC<Props> = ({ image, className, alt }) => (
  <StyledOverlayImageContainer className={className || ''}>
    <Image {...image.childImageSharp} alt={alt} />
  </StyledOverlayImageContainer>
);
export default OverlaySectionImage;
