import React, { useState } from 'react';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { Waypoint } from 'react-waypoint';

import * as utils from '@utils';
import { Stylable } from '@content/types/general';
import ArrowDown from '@components/common/ArrowDown';

const bounce = keyframes`
  from, to {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, -10px, 0);
  }
`;

const Wrapper = styled.div<StylesProps>`
  animation: ${bounce} 1.5s ease infinite;
  ${props => (!props.animate ? 'animation-play-state: paused;' : '')}
`;

interface Props extends Stylable {}
interface StylesProps {
  animate: boolean;
}

export default (props: Props) => {
  const [doAnimate, setDoAnimate] = useState(true);

  return (
    <Wrapper className={props.className} animate={doAnimate}>
      <Waypoint
        topOffset={utils.getVerticalAnimTriggerOffset()} // stop animation if arrow ~300px from bottom
        bottomOffset={utils.getVerticalAnimTriggerOffset()}
        // onEnter={() => {
        //   setDoAnimate(false);
        // }}
        // onLeave={d => {
        //   setDoAnimate(d.currentPosition === 'below'); // only animate if arrow is on bottom
        // }}
      />
      <ArrowDown />
    </Wrapper>
  );
};
