import React from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css } from '@styles';
import Icon from '@components/common/Icon';
import { Stylable } from '@content/types/general';

const StyledDownArrow = styled(Icon)<StyledDownArrowProps>``;

const Wrapper = styled.div`
  color: ${colors.green.dark};
  z-index: 1;
  text-align: center;
  margin-top: 30px;
`;

interface StyledDownArrowProps extends Stylable {}

export default ({ className, style }: StyledDownArrowProps) => (
  <Wrapper className={['arrow-down', className || ''].join(' ')}>
    <StyledDownArrow type="arrow-down" inheritColor={true} style={style || {}} />
  </Wrapper>
);
