import React, { Component } from 'react';
import { GatsbyImageProps } from 'gatsby-image';
import styled from '@emotion/styled';
import ArrowDown from '@animations/AnimationArrowDown';

import { colors, dimensions, fluidRange, respondFrom, breakpoints, css, mixins } from '@styles';
import { ImageSharp } from '@content/types/general';
import HeroBg from '@components/common/HeroBg';
import ButtonLink from '@components/common/ButtonLink';
import OverlaySectionImage from '@components/common/OverlaySectionImage';
import * as utils from '@utils';
import HeroVideoPlayer from '@components/common/HeroVideoPlayer';

export const consts = {
  scrollDown: 'SCROLL-DOWN',
};

const StyledHeroBg = styled(HeroBg)`
  margin-bottom: 60px;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-bottom: 100px;
    `
  )}

  &.no-arrow {
    margin-bottom: 0;
  }

  &.has-overlay-image {
    ${respondFrom(
      breakpoints.lg,
      css`
        margin-bottom: 200px;
      `
    )}
  }

  &.no-image-on-mobile {
    .gatsby-image-wrapper {
      opacity: 0;

      ${respondFrom(
        breakpoints.lg,
        css`
          opacity: 1;
        `
      )}
    }
  }

  &.theme-dark {
    ${respondFrom(
      breakpoints.md,
      css`
        .gatsby-image-wrapper:first-of-type {
          max-width: 1600px;
          margin: auto;
          overflow: visible !important;

          &::before {
            content: '';
            position: absolute;
            left: -20vw;
            right: -20vw;
            top: 0;
            bottom: 0;
            background-color: #1c1c1b;
          }
        }
      `
    )}
  }
`;

const StyledTextContainer = styled.div`
  ${mixins.fontSmoothing}

  .size-large & {
    ${respondFrom(
      breakpoints.md,
      css`
        top: -12%;
        position: relative;
      `
    )}
  }
`;

const StyledH1 = styled.h1<TextProps>`
  ${fluidRange('fontSize', '40px', '62px')}
  line-height: 1;
  margin-bottom: 0.4em;
  color: ${props => (props.text && props.text === 'dark' ? colors.text.default : false)};

  ${respondFrom(
    breakpoints.md,
    css`
      max-width: 640px;
    `
  )}
  .size-medium & {
    ${fluidRange('fontSize', '30px', '40px')}
    line-height: 1.3;
    margin-bottom: 0.6em;
  }
`;

const StyledParagraph = styled.div<TextProps>`
  font-size: ${dimensions.fontSize.large}px;
  ${fluidRange('fontSize', `${dimensions.fontSize.regular}px`, `${dimensions.fontSize.large}px`)}
  margin-bottom: 1.2em;
  color: ${props => (props.text && props.text === 'dark' ? colors.text.default : false)};

  &:last-of-type {
    margin-bottom: 1.5em;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      max-width: 540px;
    `
  )}

  &.size-medium > * {
    &:last-of-type {
      margin-bottom: 2.6em;
    }
  }
`;

const StyledOverlaySectionImage = styled(OverlaySectionImage)`
  > .gatsby-image-wrapper {
    ${respondFrom(
      breakpoints.lg,
      css`
        bottom: -50px;
      `
    )}
  }
`;

const Postscript = styled.p`
  font-size: ${dimensions.fontSize.large}px;
  font-weight: 500;
  margin-bottom: 10px;
  display: none;

  ${respondFrom(
    breakpoints.md,
    css`
      display: block;
    `
  )}
`;

const StyledArrowDown = styled(ArrowDown)`
  position: absolute;
  bottom: -40px;
  left: 0;
  width: 100%;
`;

interface Props {
  className?: string;
  size?: 'large' | 'medium';
  image?: GatsbyImageProps;
  imageMobile?: GatsbyImageProps;
  title?: string | React.ReactElement;
  paragraph: string | React.ReactElement;
  buttonText?: string;
  buttonTextHideOnMobile?: string;
  buttonRoute?: string;
  additionalContent?: React.ReactElement | null;
  text?: 'dark';
  noArrow?: boolean;
  overlayImage?: ImageSharp;
  postscript?: string;
  buttonOutside?: boolean;
  noImageOnMobile?: boolean;
  verticalAlign?: string;
  imageAlt?: string;
  heroTheme?: string;
  video?: string;
  videoThumbnail?: ImageSharp;
  videoThumbnailAlt?: string;
  hasVideo?: boolean;
  loading?: `auto` | `lazy` | `eager`
}

interface TextProps {
  text?: string;
}

class Hero extends Component<Props> {
  state = {
    windowWidth: 0,
  };

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    const width: number = window.innerWidth;
    this.setState({ windowWidth: width });
  };

  render() {
    const {
      className,
      size,
      image,
      imageMobile,
      title,
      paragraph,
      buttonText,
      buttonTextHideOnMobile,
      buttonRoute,
      additionalContent,
      text,
      noArrow,
      overlayImage,
      postscript,
      buttonOutside,
      noImageOnMobile,
      imageAlt,
      heroTheme,
      video,
      videoThumbnail,
      videoThumbnailAlt,
      loading,
    } = this.props;

    return (
      <StyledHeroBg
        {...(this.state.windowWidth < 768 && imageMobile ? imageMobile : image ? image : [])}
        className={`
          size-${size}
          ${className}
          ${noArrow && 'no-arrow'}
          ${overlayImage ? 'has-overlay-image' : ''}
          ${noImageOnMobile ? 'no-image-on-mobile' : ''}
          theme-${heroTheme}
          hero-bg
        `}
        additionalContent={
          <>
            {additionalContent}
            {!noArrow && size === 'medium' ? <StyledArrowDown className={'arrow-down'} /> : null}
            {overlayImage && <StyledOverlaySectionImage image={overlayImage} alt={imageAlt} />}
            {video && (
              <HeroVideoPlayer
                videoThumbnailAlt={videoThumbnailAlt}
                videoThumbnail={videoThumbnail ? videoThumbnail : undefined}
                videoUrl={video}
              />
            )}
          </>
        }
        noMinHeight={size === 'medium'}
        loading={loading ? loading : 'lazy'}
      >
        <StyledTextContainer>
          {postscript && postscript !== '' && <Postscript>{postscript}</Postscript>}
          <StyledH1 text={text}>
            {typeof title === 'string' ? utils.SafeHtml(title) : title}
          </StyledH1>

          <StyledParagraph className={`size-${size}`} text={text}>
            {typeof paragraph === 'string' ? utils.SafeHtml(paragraph) : paragraph}
          </StyledParagraph>

          {buttonText && buttonText !== '' && (
            <ButtonLink
              to={buttonRoute ? buttonRoute : '/'}
              icon="arrow-right"
              extend={buttonOutside}
            >
              {buttonText}
              {buttonTextHideOnMobile && this.state.windowWidth > 768 && (
                <span>{buttonTextHideOnMobile}</span>
              )}
            </ButtonLink>
          )}
        </StyledTextContainer>
      </StyledHeroBg>
    );
  }
}

export default Hero;
